// Useful for debugging FormData
// function logFormData(fd: FormData) {
//   for (const [key, value] of fd.entries()) {
//     console.log(key, value)
//   }
// }

// Tansforms an object into a FormData object, and snake cases the keys on the go.
export default function objectToFormData(
  obj: Record<string, any>,
  form?: FormData,
  namespace?: string,
  convertKeysToSnakeCase: boolean = true,
): FormData {
  const caseConvertKey = convertKeysToSnakeCase
    ? useSnakeCase
    : (key: string) => key

  const formData = form || new FormData()

  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      // Convert the new key to snake case
      const formKey = namespace
        ? `${namespace}[${caseConvertKey(property)}]`
        : caseConvertKey(property)

      if (obj[property] === undefined) {
        continue
      }
      else if (obj[property] instanceof Date) {
        formData.append(formKey, obj[property].toISOString())
      }
      else if (Array.isArray(obj[property])) {
        obj[property].forEach((item: any) => {
          const arrayKey = `${formKey}[]` // Format the key to have the '[]' suffix
          if (
            typeof item === 'object'
            && !(item instanceof File)
            && !(item instanceof Blob)
            && item !== null
          ) {
            objectToFormData(item, formData, arrayKey) // Recursion for nested objects within the array
          }
          else {
            formData.append(arrayKey, item)
          }
        })
      }
      else if (
        typeof obj[property] === 'object'
        && !(obj[property] instanceof File)
        && !(obj[property] instanceof Blob)
        && obj[property] !== null
      ) {
        objectToFormData(obj[property], formData, formKey)
      }
      else if (obj[property] === null) {
        // Append null values as empty strings.
        // This is not really great because it transforms every "nil" value to a string.
        // However, excluding the value isn't a great solution either, due to the way rails works.
        // It would make it impossible to erase values.
        formData.append(formKey, '')
      }
      else {
        formData.append(formKey, obj[property])
      }
    }
    else {
      console.error(`objectToFormData: ${property} does not hasOwnProperty`)
    }
  }

  // logFormData(formData)

  return formData
}
